#concours-espaces {

  .container_18 .grid_8 {
    width: 362px;
    float: right;
  }
  /* =========================
   * GENERAL SETTING
   */
  .contest-ads {
    text-align: center;
  }

  .parsley-errors-list {
    display: none;
  }
  /* =========================
   * HEADER
   */
  .concours-header {
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0;
    text-align:center;
  }
  .concours-header h1 {
    font-size: 21px;
    line-height: 1.2em;
    color: #2e2d2b;
    text-transform: uppercase;
    margin: 20px 0 0 0;
    text-align: center;
  }
  .concours-header h1 strong {
    font-size: 21px;
    line-height: 1.2em;
    color: #ab070a;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
  .concours-header h2 {
    font-size: 21px;
    line-height: 1.2em;
    color: #ab070a;
    text-transform: uppercase;
    margin: 0 0 30px;
    text-align: center;
  }


  /* =========================
   * CONTENT
   */
  .concours-layout.default-alt {
    background-attachment: fixed;
    background-position: 100% 60% !important;

    #content {
      //background-color: #2e2d2b;
    }
  }

  .concours-layout .container {
    padding-top: 3em;
    padding-bottom: 3em;
    max-width: 100%;
  }

  .concours_container {
    border-top:5px solid #ab070a;

    ul,
    ol {
      padding-left: 1em;

      @include mq(sm) {
        padding-left: 0;
      }

      @include mq(md) {
        padding-left: 1em;
      }

      font-family: 'Open Sans', sans-serif;

      li {
        font-size: .9em;
        list-style-type: disc;
      }
    }
  }

  .concours_container .grid_11 {
    display: block;
    float: left;
    width: 100%;
    min-width: 605px;
    max-width: 605px;
    background-color: #fff;
    min-height: 1268px;
  }

  .concours_container .grid_7 {
    display: block;
    float: left;
    width: 100%;
    min-width: 385px;
    max-width: 385px;
  }

  #content:after {
    content: "";
    display: table;
    clear: both;
  }

  #content > div {
    padding: 20px;
  }

  #content .box_tabs {
    margin-bottom: 20px;
  }

  #content .box_tabs a {
    font-weight: normal;
  }

  .container_18 .grid_8 {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  #content section.formulaire {
    padding: 20px;
    background-color: #2e2d2b;
    color:#fff;
  }
  #content section.formulaire h3 {
    font-size:21px;
    color:#fff;
    border-bottom: 2px solid #ab070a;
    padding: 0 0 10px;
  }
  #content section.formulaire .placeholder { color: #dfdfdf; }

  #content section.formulaire input,
  #content section.formulaire select {
    margin-top: 5px;
    font-size: 14px;
  }
  #content section.formulaire select {
    -webkit-appearance:inherit;
    -moz-appearance: inherit;
    appearance: inherit;
    background-color: #fff;
    color: #888;
  }
  #content section.formulaire label {
    width: 100%;
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }
  #content section.formulaire label.dropdown:after {
    top: 10px;
  }
  #content section.formulaire label input {
    margin-right:5px;
  }
  #content section.formulaire label input[type=checkbox] {
    display: initial;
  }
  #content section.formulaire .btn_texte {
    border:none;
    cursor: pointer;
  }
  #content section.formulaire p {
    color:#fff;
  }

  #content section.formulaire .genre label  {
    width: auto;
    margin-right: 20px;
  }

  #content section.formulaire label span {
    color:#ab070a;
    vertical-align: top;
    font-size: smaller;
    padding-left: 2px;
  }
  #content section.formulaire form select,
  #content section.formulaire form input[type=text],
  #content section.formulaire form input[type=date] {
    margin-bottom:20px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  #content section.formulaire form button[type=submit] {
    margin-top: 10px;
    width: 100%;
    border:0;
    background-color:#ab070a;
    color:#fff;
    text-align: center;
    text-transform: uppercase;
    padding:15px;
  }
  label.dropdown:after,
  label.dropdown_light:after {
    display: none;
  }
  .shadow{
    -webkit-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.6);
    -moz-box-shadow:    0px 3px 10px 0px rgba(50, 50, 50, 0.6);
    box-shadow:         0px 3px 10px 0px rgba(50, 50, 50, 0.6);
  }
  /* =========================
   * TABS
   */
  .tabpage {
    display: none;
  }
  .tabpage.active {
    display: block;
  }
  .tabpage img {
    max-width: 100%;
  }
  ul.box_tabs {
    background:#fff;
    border:none;
    text-align: center;
  }

  .box_tabs li {
    position: relative;
    display: inline-block;
    background-color:#f4f5f3;
    border:1px solid #e4e3e1;
    padding:0;
    height: 50px;
    color: #000;
    font-size: 16px;
    line-height: 12px;
    text-transform: uppercase;
    width: 100%;

    @include mq(xs) {
      width: auto;
    }

    @include mq(md) {
      margin-right: 20px;
    }
  }
  .box_tabs li:hover, .box_tabs li.active {
    background-color: #fff;
    border:1px solid #fff;
  }


  .box_tabs li:hover:before, .box_tabs li.active:before {
    content: '';
    position: absolute;
    display: block;
    top: -1px;
    bottom:inherit;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-top: 0;
  }

  .box_tabs li:hover:after, .box_tabs li.active:after {
    content: '';
    display: block;
    border-top: 2px solid #ab070a;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    opacity: 1;
  }


  .box_tabs a {
    display: block;
    cursor: pointer;
    color:#ab070a;
    padding: 20px 30px;
    outline: 0;

    @include mq(sm) {
        padding: 20px;
      }

      @include mq(md) {
        padding: 20px 30px;
      }
  }
  .box_tabs a:hover {
    color:#ab070a;
  }
  .box_tabs li.active a,
  .box_tabs li.active a:hover {
    color:#2e2d2b;
  }


  .box_tabs a:hover:before,
  .box_tabs a.active:before {
    content: '';
    display: block;
    border-top: 2px solid #ab070a;
    position: absolute;
    bottom:-2px;
    left: 0;
    width: 100%;
    opacity: 1;
  }
  h1.onglet{
    font-size: 20px;
    color: #000;
    font-weight: bold;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
  }

  .strong {
    font-family: 'Open Sans', sans-serif;
    font-weight:bold;
  }

  /* =========================
   * INFORMATIONS
   */
  section.infos {
    background: #f9fafa;
    font-family: 'Myriad Pro', Arial, sans-serif;
    font-size: 12px;
    border-top: 1px solid #2ec05a;
    margin-bottom: 50px;
  }
  section.infos span {
    font-size: 30px;
    line-height: 2.2em;
    margin: 0 20px;
    color: #dfdfdf;
  }
  input:focus {
    outline: 0;
  }
  input.parsley-error,
  select.parsley-error,
  textarea.parsley-error {
    border:1px solid red !important;
    background-color:#fff3f3;
  }
  div.parsley-required {
    margin-top: 4px;
    color: #ff4747;
  }
  ul.parsley-error-list {
    list-style-type: none;
    margin:0;
    padding:5px 0;
    font-size:12px;
    font-family: 'Myriad Pro', Arial, sans-serif;

    text-indent:6px;
    color:#a94442;
  }
  .checkbox{padding:0 0 10px 20px;}
  .checkbox input{margin-left:-20px}
  .container{
    width: 990px;
    margin: auto;
  }

  .headerLink{
    display:block;
    position:absolute;
  }
  .headerLink.one{
    width: 242px;
    height: 49px;
    top: 194px;
    left: 227px;

  }
  div.error-captcha{
    color: #ff4747;
    font-size: 14px;
    margin-top:5px;
  }


  label.dropdown, label.dropdown_light {
    height:auto;
  }
  
  .contest-form {
    input[type="text"],
    input[type="email"] {
      color: #000;
      background-color: #fff;
      width: 100%;
      padding: 4px;
    }

    select {
      width: 100%;
      padding: 4px;
    }
  }

  @media screen and (max-width: 1550px) {
    .container_18 .grid_11,
    .container_18 .grid_7 {
      max-width: none;
    }

    .container_18 .grid_11 {
      min-height: inherit;
    }
  }

  @media screen and (max-width: 1024px) {
    .contest-ads {
      text-align: center;
    }
  }

  @media screen and (max-width: 605px) {
    .container_18 .grid_11,
    .container_18 .grid_7 {
      min-width: 0;
    }
    .container_18 .box_tabs li {
      display: block;
    }
  }

}