.article-cta {
    position: relative;
    width: 100%;

    &__wrapper {
        width: 100%;
    }
    &__media {
        width: 100%;
        display: block;
        position: relative;

        @include keepRatio('16/9');
        .ads &,
        .equipements & {
            @include keepRatio('16/9',true);
        }
        img {
            transition: transform 0.5s;
            transform-origin: center center;
        }
        &:hover img {
            transform: translate3d(-50%,-50%,0) scale(1.1)!important;
        }
    }
    &__categorie {
        position: relative;
        display: inline-block;
        width: 100%;
        color: $light-charcoal;
        text-transform: uppercase;
        line-height: 1;
        font-weight: $fw--semi-bold;
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 20px;
            height: 2px;
            background-color: $light-charcoal;
            left:0;
            bottom: -5px;
        }
    }
    a.article-cta__categorie {
        &:hover {
            color:$brand-color;
        }
    }
    &__suptitle {
        position: relative;
        display: inline-block;
        width: 100%;
        color: $light-grey;
        text-transform: uppercase;
        line-height: 1;
        font-weight: $fw--semi-bold;

    }
    a.article-cta__suptitle {
        &:hover {
            color:$brand-color;
        }
    }
    &__title {
        display: inline-block;
        line-height: 1.1;
        text-transform: uppercase;

    }
    &__new {
        color: $snow-white;
        background: $brand-color;
        text-transform: uppercase;
        position:absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        padding: .5em 1em;
    }
    &__plus {
        //@include rem('margin-top', -12px);
        display: block;
        overflow: hidden;
        width: 26px;
        height: 26px;
        transform-origin: 50% 50%;
        transform: rotate(45deg) translateY(10px);
        background-color: $brand-color;
        position: absolute;
        top: 0;
        right: 0;

        &:before {
            @include rem('font-size', 22px);
            @include rem('line-height', 19px);
            content:"+";
            display: block;
            width: 25px;
            height: 25px;
            line-height: 20px;
            color: $snow-white;
            font-weight: $fw--semi-bold;
            transform-origin: 50% 50%;
            transform: rotate(-45deg) translateY(0);
            text-align: center;
            font-style: normal;
        }

        &:hover {
            //transform: rotate(-45deg) translateY(10px);
        }
    }
    a {
        text-decoration: none;
        transition: color 0.5s;
    }
    article,
    figure {
        overflow: hidden;
        position:relative;
    }
    .list a:hover,
    figure:hover  .article-cta__title a:not(.article-cta__suptitle) {
        color:$brand-color;
    }
}

.article-cta {
    &--listing {
        @extend .article-cta;

        .numeric-label {
          display: block;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 13px;
          padding: 26px 0 0 30px;
          text-shadow: 0px 0px 4px rgba(255,255,255,0.5);
          position: absolute;
        }

        .article-cta__categorie {
            @include rem('font-size', 18px);
            @include rem('padding', 10px 0 7px 0);
            height:45px;
            &:before {
                bottom: 10px;
            }
        }
        .article-cta__suptitle {
            @include rem('font-size', 12px);
            margin: 0 0 .25rem;
        }
        .article-cta__price {
            display:block;
            @include rem('font-size', 18px);
            font-weight: $fw--semi-bold;
            color: $light-charcoal;
            margin: .25rem 0 0;
        }
        .article-cta__wrapper {
            @include rem('margin', 10px 0 15px 0);
            height: 100%;
        }
        .article-cta__media,
        .article-cta__title {
            display: block;
            float: left;
            width: 50%;
        }
        .article-cta__title {
            @include rem('font-size', 22px);
            line-height: 1;
            @include rem('padding-left', 20px);
            vertical-align: middle;
            font-weight: $fw--semi-bold;
            color: $charcoal;
        }
        p {
            @include rem('font-size', 18px);
            @include rem('margin-top', 15px);
            line-height: 1.2;
            color: $charcoal;
        }

        .btn--primary {
            @include rem('margin-top', 5px);
        }

        @include mq(xs) {
            .article-cta__wrapper {
                display: block;
                width: 100%;
                margin: 0;
            }
            .article-cta__media {
                width: 100%;

            }
            .article-cta__title {
                width: 100%;
                display: block;
                padding-left: 0;
                @include rem('padding-top', 10px);
            }
        }
    }
}



.article-cta {
    &--img {
        @extend .article-cta;

        .article-cta__wrapper {
            @include rem('padding', 15px 65px 15px 15px);
            position: absolute;
            bottom: 0;
            //min-height: 100px;
            background-color: rgba(46, 45, 43, .8);

            @media screen and (max-width:1359px) {
                position: static;
            }

            &--featured {
                @extend .article-cta__wrapper;
                @include rem('padding-right', 15px);
                @include rem('padding-bottom', 78px);

                .article-cta__title {
                    @include rem('font-size', 20px);
                    font-weight: $fw--semi-bold;
                    text-transform: uppercase;
                    //@include rem('padding-right', 20px);
                }
            }
        }
        .article-cta__plus {
            top: auto;
            right: 12px;
            bottom: 25px;
            margin-top: 0;
        }
        .article-cta__title {
            @include rem('margin-top', 10px);
            @include rem('font-size', 18px);
            line-height: 1.3;
            color: $snow-white;
            text-transform: none;

        }
        .btn--primary--red,
        .btn--primary--white {
            position: absolute;
            left: 20px;
            bottom: 20px;
        }
        .white-left{right:20px;}
        @include mq(lg) {
            .article-cta__wrapper--featured {
                @include rem('padding', 15px 170px 15px 15px);
            }
            .btn--primary--red,
            .btn--primary--white {
                right: 20px;
                left: auto;
            }
            .article-cta__title {
                @include rem('padding-right', 20px);
            }
        }
    }
}


.article-cta {
    &--horizontal {
        @extend .article-cta;
        @include rem('margin', 30px 0);
        @include rem('padding', 20px 0);
        border-top: 1px solid $xlight-grey;
        border-bottom: 1px solid $xlight-grey;

        .article-cta__wrapper {
            @include rem('margin', 10px 0 15px 0);
            display: table;
            height: 100%;
        }
        .article-cta__media {
            display: table-cell;
            width: 50%;
        }
        .article-cta__title {
            @include rem('font-size', 20px);
            @include rem('padding-left', 10px);
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            font-weight: $fw--semi-bold;
            color: $charcoal;
        }
        [class*="btn"] {
            display: none;
        }

        @include mq(md) {

            @include rem('padding', 20px);
            min-height: 160px;

            .article-cta__wrapper,
            .article-cta__categorie {
                @include rem('margin-left', 220px);
            }

            .article-cta__wrapper {
                width: 100%;
                margin-bottom: 0;
            }
            .article-cta__media {

                width: 100%;
                max-width: 200px;
                display: block;
                position: absolute;
                left:20px;
                top:20px;
            }
            .article-cta__title {
                width: 100%;
                display: block;
                padding-left: 0;
            }
            [class*="btn"] {
                @include rem('margin-top', 20px);
                display: inline-block;
            }
        }
    }
}
