.shortcuts {
	> a {
		display: inline-block;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 300;
		padding-right: 35px;
		padding-left: 35px;

		&:hover {
			color: $brand-color;
		}
	}
}