// ==========================================================================
// Grid System
// ==========================================================================

@mixin grid($class: '.grid', $grid-columns : 12, $gutter : 0, $max-width : false, $flexbox-enabled : false){

    #{$class} {
        width: 100%;
        @if($max-width == false){
            $max-width : calc(100% - #{$gutter});
        }
        max-width: $max-width;
        margin: 0 auto;

        @if($gutter != 0){
            padding: 0 $gutter/2;
        }


        .row {
            display: block;
            margin-left:-$gutter/2;
            margin-right:-$gutter/2;
            position: relative;

            @if $flexbox-enabled {
                
            }
            // Columns
            & > * {
                display: block;
                width: 100%;

                @if $flexbox-enabled {
                   
                }
            }
            & > * {
                @if($gutter != 0){
                    padding: 0 $gutter/2 $gutter;
                }
            }
        }

        @each $bp, $w in $breakpoints {
            @media only screen and (min-width: #{$w}) {
                @for $i from 1 through $grid-columns {

                    @if $flexbox-enabled {
                        
                     } @else {
                        .col-#{$bp}-#{$i} {
                            float: left;
                            width: 100% / $grid-columns * $i;
                        }
                    }
                    .col-#{$bp}-offset-#{$i} {
                        margin-left: 100% / $grid-columns * $i;
                    }
                    .col-#{$bp}-pull-#{$i} {
                        position: relative;
                        right: percentage(($i / $grid-columns));
                    }
                    .col-#{$bp}-push-#{$i} {
                        position: relative;
                        left: percentage(($i / $grid-columns));
                    }
                }
            }
        }
    }

    .parent-sticky {
        display: block;
    }
    .box--sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
    }
    aside .row.adgear-m0 {
        overflow : hidden;
    }
}
