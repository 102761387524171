// ==========================================================================
// Components
// ==========================================================================
@import 'header';
@import 'icons';
@import 'shortcuts';
@import 'footer';
@import 'buttons';
@import 'buttons_socials';
@import 'diamond-shape';
@import 'breadcrumb';
@import 'pagination';
@import 'navs';
@import 'repertoire-filtre';
@import 'article';
@import 'article-cta';
@import 'gallery';
@import 'comments';
@import 'metas';
@import 'tabs';
@import 'flexslider';
@import 'lightslider';

.grid .filterContainer .row > [class*="col-"] {
    margin-bottom: 0;
    padding-bottom: 0;
}


.filterContainer {
    margin-top: 1em;
    margin-bottom: 2em;
    .row {
        @include clearfix;
    }
}

#filters_search {
    @include clearfix;
}

.filters_search {
    @include clearfix;
    width: 100%;
    overflow: hidden;
    height: 0;


    &.is-open {
        height: auto;
        overflow: visible;
        padding-top: .5em;
    }
}
