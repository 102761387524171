.layout-inscription {
	@extend .col-sm-6;
	@extend .col-sm-offset-3;

	margin:55px auto;


	&__title {
		@extend .mainTitle;
		text-align: left;

		margin-top: 50px;
		margin-bottom: 40px;
		
		&:before {
			width: 100%;
			left:0;
			margin-left: 0;
		}
	}


	&__formulaire {

		.signupPartOne,
		.signupPartTwo {

			&__userInfo1 {
				margin-bottom: 30px;
			}
			.row,
			.row > * {
				padding-bottom: 0;
			}

			.form-group {
				@extend .col-sm-6;
				padding-bottom: 0;

				&--fullWidth {
					@extend .col-sm-12;
				}
			}
		}
		.signupPartThree {
			.form-group {
				@include rem('margin-bottom', 15px);
			}
		}
	}
	.spacing-intro {
		@include rem('margin', 20px 0 60px 0);
	}
	.spacing-outro {
		@include rem('margin', 40px 0 30px 0);
	}

	.button-container {
		@include rem('margin', 40px 0 60px 0);
	}
}