ul.breadcrumb {
    //@include rem('margin', 25px 0 0 0);
    padding:0;
    text-transform: uppercase;
    background: $xxlight-grey;
    padding: 1em 20px;
    width: 100%;
    //transition: all 0.5s;

    .has-mobilenav-open &,
    .has-usernav-open & {
       // background: $snow-white;
    }

    li {
        display: inline-block;

        &:not(:last-child):after {
            @include rem('margin', 0 8px);
            display: inline-block;
            font-weight: normal;
            font-family: $ff-fa;
            content:"\f105";
            position: relative;
            top: 1px;
        }
        &:last-child {
            a {
                color: $light-charcoal;
                font-weight: bold;

                &:hover {
                    color: $charcoal;
                }
            }
        }
    }
}
