// ==========================================================================
// Sections
// ==========================================================================

section {
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    &.serdy-ctas,
    &.grid-listing--recommended,
    &.ads-ctas {
        @extend .spaced;
        @extend .spaced--bordered;
        padding-bottom: 1.5em;
        h3 {
            @extend .mainTitle;
            margin: 0 0 1.5em;
        }
    }
    &.serdy-ctas {
        padding-bottom: 3em;
        background: $xxlight-grey;
        display: none;
        @include mq(xs) {
            display: block;
        }
    }
}

.header {
    max-width: 100em;
    margin: 0 auto;
    padding: 1em 25px;
    width: 100%;

    h1 {
        @extend .mainTitle
    }
    em {

    }

    &__rss {
        float: right;
    }
}

.featured {
    background: $xxlight-grey;
    .grid--featured {
        .top {
            &--small {
                @extend .col-sm-6;
                @extend .col-md-3;
                figure .article-cta__media {
                    @include keepRatio('8/9');
                }
                &:nth-of-type(2){
                    @extend .col-md-pull-6;
                }
                @media screen and (max-width:1359px) {
                    figcaption {
                        position: static;
                    }
                }

            }
            &--large {
                @include mq(sm){
                    margin-top: -7px;
                }
                @extend .col-md-6;
                @extend .col-md-push-3;
                @media screen and (max-width:1359px) {
                    figcaption {
                        position: static;
                    }
                }
            }
        }
        .bottom {
            &--small {
                @extend .col-sm-6;
                @extend .col-md-3;
                @media screen and (max-width:1359px) {
                    figcaption {
                        position: static;
                    }
                }
            }
        }
        .single {
            @extend .col-sm-4;
            padding-bottom: 0;
            @media screen and (max-width:1359px) {
                figcaption {
                    position: static;
                }
            }
        }

        @media only screen and (max-width: 767px){
            padding: 0;
        }

        .owl-carousel {
            margin: 0;

            .owl-wrapper-outer {
                padding: 0;
            }

            .owl-item {
                .top--small,
                .top--large,
                .bottom--small {
                    width:100%;
                    figure .article-cta__media {
                        @include keepRatio('16/9');
                    }
                }
            }

            .owl-controls {
                margin-top: 10px;
                text-align: center;

                .owl-page{
                    display: inline-block;
                }
                .owl-page span{
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin: 5px 7px;
                    opacity: 0.5;
                    border-radius: 20px;
                    background: $light-grey;
                }
                .owl-page.active span,
                &.clickable .owl-page:hover span{
                    opacity: 1;
                    background: $brand-color;
                }
            }
        }
    }
    &--dominance {
        @extend .featured;
        @include mq(md){
            padding-top: 8vw;
            .top--small {
                display:none;
            }
            .top--large {
                figcaption {
                    position: absolute !important;
                }
            }
            .top--large,
            .bottom--small {
                padding: 10px 5px 0;
            }
            .bottom--small:nth-of-type(4) {
                clear:left;
            }
            &.single-news {
                transform: translate3d(0,0,0);
                .grid {
                    margin-top: 33%;

                }
                .layout-3col {
                    margin-top: 0;
                    .article__media {
                        position: fixed;
                        width: 50%;
                        left: 25%;
                        top: 8vw;

                        figcaption {
                            background-color: $xxlight-grey;
                            width: 102%;
                            height: 27px;
                            bottom: -2px;
                        }
                    }
                }
            }
        }
    }
    &--home {
        @extend .featured;
        background: none;
        .top--small,
        .top--large,
        .bottom--small {
            background: white;
        }
    }
    >a {
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        padding: 1em 20px;
        width: 100%;
        border-bottom: 1px solid $xlight-grey;
        &:hover {
            color: $brand-color;
        }
    }
}

.grid-listing {
    @extend .grid;

    padding-left: 0;
    padding-right: 0;

    &__item {
        float: left;
        width: 100%;
        min-width: 320px;
        text-align: center;
        margin: 0 0 $gutter-width;
        padding: 0 $gutter-width/4;

        article {
            margin: 0 auto;
            text-align: left;
        }

        @include mq(xs) {
            //float: left;
            width: 50%;
            margin-bottom: $gutter-width;
            min-height: 300px;
            //margin-bottom: 0;
            //padding-bottom: 0;
            &.big,
            &.ad--big {
                //min-height: 0;
                margin-bottom: $gutter-width * 2;
                min-height: 260px;

            }
            article {
                width: 300px;
            }
        }
        @include mq(mmd) {
            width: calc(100% / 3);

        }
        @include mq(lg) {
            width: 25%;
        }
    }
    /*
    &--home {
    @extend .grid-listing;
    .grid-listing__item:nth-child(1) {
    float: right;
}
.article-cta__categorie:before {
bottom: 10px;
}
@include mq(xs) {
.grid-listing__item.ad {
margin: 90px 0 0;
}
article {
min-height: 300px;
}
.article-cta__wrapper {
display: block;
width: 100%;
margin: 0;
}
.article-cta__media {
width: 100%;
display: block;
}
.article-cta__title {
width: 100%;
display: block;
padding-left: 0;
@include rem('padding-top', 10px);
}

}
}*/



&--section-ctas {
    @extend .grid-listing;

    .grid-listing__item {
        min-height: 0;
        width: 100%;
        margin: 2em 0;
    }
    article {
        width: 100%;
        max-width: 700px;
        height: 240px;
        .column {
            position: relative;
            height: 240px;
            width: 50%;
            float: left;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            &:last-child {
                box-shadow: inset 0 0 80px 0px rgba(0,0,0,.1);
            }
            .column-inner {
                position: absolute;
                width: 60%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

            }
        }
        .btn--primary {
            display: none;
        }
        .article-cta__categorie {
            height: auto;
            &:before {
                bottom: -5px;
            }
        }
        .article-cta__title {
            padding: .5em 0 1em;
            display: none;
            @include mq(xs) {
                display: block;
            }
        }

        .radio {
            position: absolute;
            width: 60px;
            height:60px;
            background:$brand-color;
            color:white;
            border-radius: 30px;
            z-index:2;
            top: 50%;
            left: 25%;
            transform: translate(-50%,-50%);
            text-align: center;
            transition: background-color 0.5s;

            &:before {
                @extend .fa;
                @extend .fa-play;
                line-height:60px;
                font-size:1.5em;
                position: relative;
                left:3px;
            }
            &:hover {
                background: $darth-vader;
            }
        }
    }

    @include mq(xs) {
        article {
            min-height: 0;
            .btn--primary {
                display: inline-block;
            }
            .radio {
                left: 50%;
            }
        }
    }
    @include mq(mmd) {

    }
    @include mq(lg) {
        .grid-listing__item {
            min-height: 0;
            width: 50%;
            padding: 0 20px;
            //padding: 0 calc( (50% - 600px) / 4 );
        }
    }
}

&--home {
    @extend .grid-listing;

    padding-top: 3em;
    padding-bottom: 3em;
    @extend .spaced--bordered;

    .grid-listing__item.ad--big:nth-child(1) {
        float: right;
    }

    // .grid-listing__item + .grid-listing__item{
    //     padding-top: 3em;
    // }


    @include mq(xs) {
        margin-top: 3em;
        padding-bottom: 0;
        .grid-listing__item{
            //min-height: 600px;
            & + .grid-listing__item {
                padding-top: 0;
            }
        }
        .grid-listing__item:nth-child(2n+1){
            clear:both;
        }
    }
    @include mq(mmd) {
        .grid-listing__item:nth-child(2n+1){
            clear:none;
        }
        .grid-listing__item:nth-child(3n+1){
            clear:both;
        }
    }
    @include mq(lg) {
        .grid-listing__item:nth-child(2n+1),
        .grid-listing__item:nth-child(3n+1){
            clear:none;
        }
        .grid-listing__item:nth-child(4n+1){
            clear:both;
        }
    }
}

&--directory {
    @extend .grid-listing;
    .grid-listing__item.ad--big:nth-child(1) {
        float: right;
    }
}

&--ads {
    @extend .grid-listing;

    .grid-listing__item {
        display: none;
        min-height: 0;
        li {
            position: relative;
            list-style: none;
            margin-left: 20px;
            margin-bottom: 1.5em;
            text-transform: uppercase;
            a {
                font-weight: bold;
                &:hover {
                    color: $brand-color;
                }
            }
        }
        li:before {
            content: "";
            display: block;
            overflow: hidden;
            width: 10px;
            height: 10px;
            transform-origin: 50% 50%;
            transform: rotate(45deg) translateY(10px);
            background-color: $brand-color;
            position: absolute;
            top: 0;
            left: -12px;
        }
        &:nth-of-type(-n+2) {
            display:block;
        }
    }
    .view-more {
        width: 100%;
        padding: 1em 20px;
        text-align: center;
        clear: both;
        hr {
            display: none;
        }
    }

    @include mq(xs) {
        article {
            min-height: 0;
        }
        .article-cta__wrapper {
            display: block;
            width: 100%;
            margin: 0;
        }
        .article-cta__media {
            width: 100%;
            display: block;
        }
        .article-cta__title {
            width: 100%;
            display: block;
            padding-left: 0;
            @include rem('padding-top', 10px);
        }
        .view-more {
            padding: 2em calc( (100vw - 600px) / 4 ) 0;
        }
    }
    @include mq(mmd) {
        .grid-listing__item:nth-of-type(-n+3) {
            display:block;
        }
        .view-more {
            float:right;
            clear:none;
            text-align:left;
            width: calc(100% / 3);
            padding: 0 calc( ((100% / 3) - 300px) / 2 );
            hr {
                display: block;
            }
        }
    }
    @include mq(lg) {
        .grid-listing__item:nth-of-type(-n+4) {
            display:block;
        }
        .view-more {
            width: 50%;
            padding: 0 calc( (50% - 600px) / 4 );
        }
    }
}

&--recommended {
    @extend .grid-listing;
    @extend .spaced;
    @extend .spaced--bordered;
}

&--fixed1row {
    .grid-listing__item {
        display: none;
    }
    .grid-listing__item.ad--big,
    .grid-listing__item:nth-of-type(-n+2) {
        display: block;
    }
    @include mq(xs) {
        .grid-listing__item {
            margin-bottom: 0;
            min-height: 600px;
        }
        .grid-listing__item:nth-of-type(-n+1) {
            display: none;
        }
        .grid-listing__item:nth-of-type(-n+2) {
            margin-left: 50%;
        }
        .grid-listing__item.ad--big {
            position: absolute;
        }
    }
    @include mq(mmd) {
        .grid-listing__item:nth-of-type(-n+2) {
            display: block;
            margin-left: 0;
        }
        .grid-listing__item:nth-of-type(1) {
            margin-left: calc(100% / 3);
        }
    }
    @include mq(lg) {
        .grid-listing__item {
            display: block;
            margin-left: 0;
        }
        .grid-listing__item:nth-of-type(1) {
            margin-left: calc(100% / 4);
        }

    }
}

&--fixed2rows {
    .mainTitle {
        margin-bottom: 1.5rem;
    }
    .grid-listing__item {
        display: none;
    }
    .grid-listing__item.ad--big,
    .grid-listing__item:nth-of-type(-n+2) {
        display: block;
    }
    @include mq(xs) {
        .grid-listing__item:nth-of-type(-n+3) {
            margin-left: 50%;
        }
        .grid-listing__item.ad--big {
            position: absolute;
        }
    }
    @include mq(mmd) {
        .grid-listing__item:nth-child(-n+5) {
            display: block;
            margin-left: 0;
        }
        .grid-listing__item:nth-of-type(1),
        .grid-listing__item:nth-of-type(3) {
            margin-left: calc(100% / 3);
        }

    }
    @include mq(lg) {
        .grid-listing__item,
        .grid-listing__item:nth-of-type(3) {
            display: block;
            margin-left: 0;
        }
        .grid-listing__item:nth-of-type(1),
        .grid-listing__item:nth-of-type(4) {
            margin-left: calc(100% / 4);
        }
    }
}

&--serdy {
    @extend .grid-listing;


    .grid-listing__item {
        display: none;
        min-height: 0;
        margin: 0;

        .logo {
            display: inline-block;
            padding: 0;
            height: 30px;
            width: auto;
            position: relative;
            z-index: 20;
            margin-bottom:.5em;

            svg {
                height: 100%;
            }

            &--evasion {
                svg {
                    path {
                        fill: $evasion;
                    }
                }
            }
            &--zeste {
                svg {
                    margin-left: -70px;
                    path {
                        fill: $zeste;
                    }
                }
            }
            &--recettes {
                svg {
                    margin-left: -70px;
                    path {
                        fill: $recettes;
                    }
                }
            }
            &:hover * {
                fill: $charcoal;
            }
        }
    }
    .grid-listing__item:nth-child(-n+2) {
        display: block;
    }
    @include mq(xs) {
        min-height: 300px;
        .article-cta__wrapper {
            display: block;
            width: 100%;
            margin: 0;
        }
        .article-cta__media {
            width: 100%;
            display: block;
        }
        .article-cta__title {
            width: 100%;
            display: block;
            padding-left: 0;
            @include rem('padding-top', 10px);
        }
    }
    @include mq(mmd) {
        .grid-listing__item:nth-child(-n+3) {
            display: block;
        }
    }
    @include mq(lg) {
        .grid-listing__item {
            display: block;
        }

    }
}
}


.grid {
    .banner {
        width: 100%;
        @extend .col-sm-10;
        @extend .col-sm-offset-1;
        figure {
            @include keepRatio('16/9');
        }
        padding: 0;
    }
    .layout-3col {
        @extend .row;
        position: static;
        margin-top: 3em;
        main {
            position:relative;
            width: 100%;
            @include mq(sm) {
                width: calc(100% - 300px - #{$gutter-width});
                float: left;
            }
            @include mq(lg) {
                padding-left: calc(250px + #{$gutter-width}/2);
                padding-right: calc(#{$gutter-width});
            }
            @include mq(xl) {
                padding-left: calc(300px + #{$gutter-width}/2);
                padding-right: calc(#{$gutter-width}*2);
            }
        }
        aside {
            width: 100%;
            display: none;
            article {
                @extend .col-xs-6;
                @extend .col-sm-12;
                padding-bottom: $gutter-width/2;
            }
            @include mq(sm) {
                width: calc(300px + #{$gutter-width});
                float: right;
                display: flex;
                flex-direction: column;
            }
        }
    }
    .layout-2col {
        @extend .row;
        margin-top: 3em;
        margin-left: 0;
        margin-right: 0;
        main {
            @extend .col-sm-9;
            @include mq(sm) {

            }
            @include mq(lg) {

            }
            @include mq(xl) {

            }
        }
        aside {
            @extend .col-sm-3;
            article {

            }
            @include mq(xs) {

            }
            @include mq(sm) {

            }
        }
    }

    .layout-3col-special {
        @extend .row;
        position: static;
        margin-top: 3em;
        main {
            position:relative;
            width: 100%;
            @include mq(sm) {
                width: calc(100% - 300px - #{$gutter-width});
                float: left;
            }
            @include mq(lg) {
                padding-left: calc(290px + #{$gutter-width}/2);
                padding-right: calc(#{$gutter-width});
            }
            @include mq(xl) {
                padding-left: calc(360px + #{$gutter-width}/2);
                padding-right: calc(#{$gutter-width}*2);
            }
        }
        aside {
            width: 100%;
            display: none;
            article {
                @extend .col-xs-6;
                @extend .col-sm-12;
                padding-bottom: $gutter-width/2;
            }
            @include mq(sm) {
                display: block;
                width: calc(300px + #{$gutter-width});
                float: right;
            }
        }
    }

}

.grid--directory-item {
    margin-top: 3em;
    max-width: 100em;
    margin-left: auto;
    margin-right: auto;
    main {
        width:100%;
        .grid--directory-item__content,
        .grid--directory-item__picture {
            width: 100%;
            min-width: 320px;
            margin: 0 auto;
            padding: 0 $gutter-width/2 $gutter-width;
            figure {
                //@include keepRatio('16/9');
                width: 100%;

                img {
                    min-width: auto;
                }
                figcaption {
                    font-size: .875em;
                }
            }
        }
        @media only screen and (min-width:900px) {
            float:left;
            width: calc(200% / 3);
        }
        @include mq(lg) {
            width:75%;
            .grid--directory-item__content,
            .grid--directory-item__picture {
                width: 50%;
                float: left;
                &>figure,
                &>article{
                    width:450px;
                }

            }
        }

    }
    aside {
        width:100%;
        text-align: center;

        .adgear {
            margin: 0;
        }
        .item-google-map {
            margin: 0 $gutter-width / 2 1em;
        }
        .coordonnees {
            margin: 0 20px 1em;
            text-align: left;

            @media screen and (min-width: 900px) {
                width: 300px;
                margin: 0 auto 1em;
            }

            h4 {
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: .5em;
            }

            p {
                margin: 0 0 .5em;
            }

            a {
                display: block;
            }

            address {
                font-style: normal;
            }
        }

        @media only screen and (min-width:900px) {
            float:left;
            width: calc(100% / 3);
            .item-google-map {
                width:300px;
                margin: 0 auto 1em;
            }
        }
        @include mq(lg) {
            width: 25%;

        }
    }
}

.search-results {
    margin-bottom: 3em;
    @extend .grid;
}
.grid--search {

    list-style: none;
    li {
        overflow: hidden;
    }
    figure {
        @extend .col-xs-4;
        @extend .col-md-2;
        a {
            width: 100%;
            display: block;
            @include keepRatio('16/9');
        }
        picture {
            width: 100%;
            transition: transform 0.5s;
            transform-origin: center center;
        }
        &:hover picture {
            transform: scale3d(1.1,1.1,1);
        }
    }
    &__result {
        @extend .col-xs-8;
        @extend .col-md-10;
        position:relative;
        a.btn--primary {
            position: absolute;
            bottom: $gutter-width;
            right: $gutter-width / 2;
        }
        p {
            margin: 1em 6em 0 0;
        }
    }
}

.sharing,
.gallery {
    @extend .spaced;
}

.petites-annonces-gallery{
  max-width:768px;
}
