// ==========================================================================
// Typography
// ==========================================================================

@import url("//hello.myfonts.net/count/2f9d19");

@font-face {
	font-family: 'SantEliaRough-Regular';
	src: url('#{$ff-path}2F9D19_0_0.eot');
	src: url('#{$ff-path}2F9D19_0_0.eot?#iefix') format('embedded-opentype'),
		url('#{$ff-path}2F9D19_0_0.woff2') format('woff2'),
		url('#{$ff-path}2F9D19_0_0.woff') format('woff'),
		url('#{$ff-path}2F9D19_0_0.ttf') format('truetype');}

@font-face {
	font-family: 'Iron-Brine';
	src:  url('#{$ff-path}Iron-Brine.ttf.woff') format('woff'),
		url('#{$ff-path}Iron-Brine.ttf.svg#Iron-Brine') format('svg'),
		url('#{$ff-path}Iron-Brine.ttf.eot'),
		url('#{$ff-path}Iron-Brine.eot?#iefix') format('embedded-opentype'); 
	font-weight: normal;
	font-style: normal;
}

html {
	font-size: $fs-base-html;
}

body {
	font-family: $ff-base;
	font-size: $fs-base;
	color: $c-base;
	line-height: $lh-base;
}


a {
	cursor: pointer;
	u {
		text-decoration: underline;
	}
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Iron-Brine';
	line-height: $lh-base;
	text-transform: uppercase;
}

.h1 { font-size: $h1; margin: 0 0 .5em; @extend .articleTitle; }
.h2 { font-size: $h2; margin: 1em 0 0.5em; }
.h3 { font-size: $h3; margin: 0 0 0.5em;
	*+& {
		margin: 1em 0 0.5em;
	}
}
.h4 { font-size: $h4; margin: 1em 0 0.5em; }
.h5 { font-size: $h5; margin: 1em 0 0.5em; }
.h6 { font-size: $h6; margin: 1em 0 0.5em; }

.link {
	color: $brand-color;
	font-weight: $fw--bold;
	text-decoration: none;
	@extend %transition-base;

	@media screen and (min-width: $nohover-width) {
		&:hover {
			color: darken($brand-color,50%);
		}
	}

	&:focus {

	}

	&[disabled] {

	}

	
}

.block-margins { margin: 1.5em 0; }

.strong {
	font-weight: $fw--bold;
}

.italic {
	font-style: italic;
}

.unordered-list,
.ordered-list {
	@extend .unlist;
	@extend .block-margins;

	li {
		position: relative;
		margin-top: em(6px);
		margin-bottom: em(6px);
	}

}

.unordered-list {
	padding-left: 20px;

	> li {
		ul { @extend .m0; }

		&:before {
			position: absolute;
			top: 6px; left: -13px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5.5px 0 5.5px 6px;
			border-color: transparent transparent transparent $brand-color;
			border-radius: 2px;
		}

		li {
			&:before {
				position: absolute;
				top: 0; left: -13px;
				content: "\f105";
				font-family: $ff-fa;
				color: $brand-color ;
				border: none;
			}
		}
	}
}

.ordered-list {
	padding-left: 30px;
	counter-reset: item;

	li { 
		&:before {
			content: counters(item, ".")" - ";
			counter-increment: item;
			color: $color-secondary;
			font-weight: $fw--reg;
		}
	}
	
	> li {
		ol { @extend .m0; }

		&:before {
			position: absolute;
			top: 0; left: -25px;
		}

		li {
			&:before {
				position: absolute;
				left: -40px;		
			}

			li:before {
				left: -53px;	
			}
		}
	}
}

.list {
	padding-left: 0;
	li {
		@include rem('font-size', 14px);
		//@extend .p;
		list-style: none;
		position:relative;
		margin-left: 18px;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-weight: $fw--semi-bold;

		&:before {
			@include rem('font-size', 9px);
			content: '\f04d';
			font-family: $ff-fa;
			color: $brand-color;
			position:absolute;
			top:-3px;
			left: -10px;
			transform-origin: 50% 50%;
			transform: rotate(45deg) translateY(10px);
		}
	}
}

.blockquote {
	@include rem('font-size', 19px);
	font-weight: $fw--semi-bold;
	font-style: italic;
	font-family: $ff-quote;
}

.typography {
	h1, .h1 { @extend .h1; }
	h2, .h2 { @extend .h2; }
	h3, .h3 { @extend .h3; }
	h4, .h4 { @extend .h4; }
	h5, .h5 { @extend .h5; }
	h6, .h6 { @extend .h6; }
	p {  @extend .block-margins; font-size: 1.125em; }
	a { @extend .link; }
	strong, b { @extend .strong; }
	em, i { @extend .italic; }
	ul:not(.parsley-errors-list):not(.select-options) { @extend .unordered-list; }
	ol { @extend .ordered-list; }
	blockquote { @extend .blockquote; }
	.fa:before {
		//color: $color-secondary;
		margin-right: .5em;
	}
	figure,
	img {
		max-width: 100%;
	}
	.iframe-wrapper {
		@include keepRatio('16/9');
	}
}

.sectionTitle {
	@extend .article-cta__categorie;
	@include rem('margin', 15px 0);
	text-align: left;

	&--noBorder {
		@extend .article-cta__categorie;
		@include rem('margin', 15px 0);
	}
}
body .sectionTitle--noBorder:before {
	display: none;
}

.mainTitle {
	position: relative;
	display: inline-block;
	width: 100%;
	color: $light-charcoal;
	text-transform: uppercase;
	font-size: 1.25rem;
	line-height: 1.3;
	font-weight: $fw--semi-bold;
	text-align: center;
	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 40px;
		height: 2px;
		background-color: $brand-color;
		left:50%;
		margin-left: -20px;
		bottom: -5px;
	}
}

.articleTitle {
	font: normal 2em/1 $ff-heading;
	@include mq(sm) {
		font-size: 2.75em
	}
	@include mq(md) {
		font-size: 3.75em
	}
}
.asideTitle {
	font: bold .875em/1 $ff-base;
	color: $light-charcoal;
	text-transform: uppercase;
	margin-bottom: $gutter-width/2;
}

