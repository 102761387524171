// ==========================================================================
// Main
// ==========================================================================

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    background: $snow-white;
}

body {
    background-position: center 135px !important;
}

hr {
    margin: 1em auto;
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $xlight-grey;

    &.pad {
        width: calc(100% - #{$gutter-width});
        margin: 1em $gutter-width/2;
    }
}

@include grid('.grid', 12, $gutter-width, 100em);
@include grid('.grid--featured', 12, 10px, 100%);

.single-page {
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
}

.typography .iframe-wrapper {
    @include keepRatio('16/9');
}

aside .adgear {
    display: block;
    margin: 1.5em 0;
}
aside .adgear-m0 .adgear {
    margin: 0;
    padding-bottom: 20px;
}

.ad--leaderboard {
    width: 100%;
    margin: auto 0;
    text-align: center;
    display: block;

    @include mq(md) {
        display: block;
    }

    .grid & {
        margin: 3em auto;
    }
}

.spaced {
    margin-top: 1.5em;
    padding-top: 1.5em;
    &--double {
        margin-top: 3em;
        padding-top: 3em;
    }
    &--bordered {
        border-top: 1px solid $xlight-grey;
    }
}

.item-google-map {
    //margin: 2em 0;
}
.item-google-map .iframe-wrapper {
    @include keepRatio('4/3');
}

.has-skin {

    .skin {
        display: none;
        @include mq(md) {
            position: absolute;
            left : 0;
            padding-top: 36%;
            right : 0;
            top : 0;
            background-size: 100% auto;
            background-position: top center;
            background-repeat: no-repeat;
            z-index: 0;
            display: block;
            border: 0;
            background-color: transparent;
        }
    }

}
