
.btn--share {
    @extend .btn--primary;
    @include rem('padding', 13px 25px);
    @include rem('margin-right', 10px);
    @include rem('margin-bottom', 13px);

    &:before {
        @include rem('margin-right', 6px);
        display: inline-block;
        font-weight: normal;
        font-family: $ff-fa;
        color: $snow-white;
    }
}

.btn--facebook--share {
    @extend .btn--share;
    border: 0;
    color: $snow-white;
    background-color: $facebook;
    &:before {
        content:"\f09a";
    }
}
@include mq(md) {
    .btn--facebook--share {
        margin-bottom: 0;
    }
}

.btn--twitter--share {
    @extend .btn--share;
    border: 0;
    color: $snow-white;
    background-color: $twitter;
    &:before {
        content:"\f099";
    }
}

.btn--pinterest--share {
    @extend .btn--share;
    border: 0;
    color: $snow-white;
    background-color: $pinterest;
    &:before {
        content:"\f0d2";
    }
}

.btn--mail--share {
    @extend .btn--share;
    border: 0;
    color: $snow-white;
    background-color: $brand-color;
    &:before {
        @include rem('font-size', 13px);
        content:"\f0e0";
    }
}


.socials {
    text-align: left;
}
.btn--socials {
    @extend .btn;
    @include rem('margin', 0 5px 5px 0);
    width: 38px;
    height: 38px;
    overflow: hidden;

    @include mq(md) {
        @include rem('margin-bottom', 0);
    }

    &:before {
        content: "";
        display: block;
        overflow: hidden;
        width: 26px;
        height: 26px;
        transform-origin: 50% 50%;
        transform: rotate(45deg) translateY(10px);
        background-color: #000;
        position: absolute;
        top: -1px;
        left: 13px;
    }
    &:after {
        display: block;
        width: 36px;
        height: 36px;
        line-height: 40px;
        position: relative;
        z-index: 1;
        font-family: $ff-fa;
        color: $snow-white;
        text-align: center;
        position: relative;
    }
}

.btn--facebook--diamond {
    @extend .btn--socials;

    &:before {
        background-color: $facebook;
    }
    &:after {
        content:"\f09a";
        left: 1px;
    }
}
.btn--twitter--diamond {
    @extend .btn--socials;

    &:before {
        background-color: $twitter;
    }
    &:after {
        content:"\f099";
        left: 1.5px;
    }
}
.btn--pinterest--diamond {
    @extend .btn--socials;

    &:before {
        background-color: $pinterest;
    }
    &:after {
        content:"\f0d2";
        top: -1px;
        left: 1.5px;
    }
}
.btn--mail--diamond {
    @extend .btn--socials;

    &:before {
        background-color: $brand-color;
    }
    &:after {
        @include rem('font-size', 13px);
        content:"\f0e0";
        top: -1px;
        left: 1px;
    }
}

.socials, .sharing{
    .at-icon-wrapper{
        display:none;
    }

    .pin_it_iframe_widget{
        display:none;
    }
}
