.icon {
    display: none;
    @extend .fa;
    padding-left: 22px; 
    line-height: #{$nav-height - 1};
    @include rem('font-size', 23px); 
    @include mq(md) {
        display: inline-block;
    }
}
.icon--facebook {
    @extend .fa-facebook;
    &:hover:before {
        color: $facebook;
    }
}
.icon--twitter {
    @extend .fa-twitter;
    &:hover:before {
        color: $twitter;
    }
}
.icon--instagram {
    @extend .fa-instagram;
    &:hover:before {
        color: $instagram;
    }
}