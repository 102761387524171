// ==========================================================================
// Animations
// ==========================================================================

@keyframes fade {
	0% { opacity: 1; }
	100% { opacity: 0.3; }
}

@keyframes fadeIn {
    from { opacity: 0; visibility: hidden; display: none;}
    to   { opacity: 1; visibility: visible; display: block;}
}

@keyframes fadeOut {
	from { opacity: 1; visibility: visible; display: block;}
	to { opacity: 0; visibility: hidden; display: none;}
}