// ==========================================================================
// Mixins
// ==========================================================================


///
/// Change placeholder color
/// --------------------------------------------------
///
/// @example scss - Usage
///   input {
///     @include placeholder { color: red; }
///   }

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder { @content; }
  }
}


///
/// Rem with pixel fallback
/// --------------------------------------------------
///
/// @example scss - Usage
///   @include rem('padding',10px 15px)
///   @include rem('font-size',10px)

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: strip-unit($value);
      $base: strip-unit($fs-base-html);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / $base + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * $base + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}


///
/// Provides an easy way to include a clearfix for containing floats.
/// --------------------------------------------------
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
///   .element {
///     @include clearfix;
///   }
///
/// @example css - CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: table;
///   }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
/// --------------------------------------------------
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
/// @todo Remove height argument in v5.0.0

@mixin hide-text($height: null) {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;

  @if $height {
    @warn "The `hide-text` mixin has changed and no longer requires a height. The height argument will no longer be accepted in v5.0.0";
  }
}






@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}



//
// Ration element keeper
// --------------------------------------------------

// figure {
//   @include keepRatio('16/9');
//   img,
//   iframe {
//      ...
//   }
// }

@function gcd($a,$b){
    @if $b == 0{
        @return $a;
    }@else{
        @return gcd($b,$a % $b);
    }
}

@function getRatio($w,$h){
    $r:gcd($w,$h);
    @return "#{$w / $r}/#{$h/$r}";
}

@mixin keepRatio($ratio:'1/1', $contain:false){
    // Usage @include keepRatio('16/9')

    $ratio1:str-slice($ratio,1,(str-index($ratio,'/') - 1));
    $ratio2:str-slice($ratio,(str-index($ratio,'/') + 1));
    position: relative;
    overflow: hidden;
    &:before{
        content:'';
        display: block;
        width: 100%;
        padding-top: to-number($ratio2) * 100 / to-number($ratio1) * 1%;
    }
    >iframe,
    >video{
        display: block;
        position: absolute;
        top: 50%!important;
        left: 50%!important;
        margin: auto;
        transform: translate3d(-50%,-50%,0);
        width: 100%;
        height:100%;

        &:fullscreen {
          transform: none!important;
          z-index:9999;
        }
        &:-webkit-full-screen {
          transform: none!important;
          z-index:9999;
        }
        &:-moz-full-screen {
          transform: none!important;
          z-index:9999;
        }
        &:-ms-fullscreen {
          transform: none!important;
          z-index:9999;
        }
    }

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
      transform: translate3d(-50%,-50%,0);
      width: auto;
      height: auto;
    }
    @if ($contain == true) {
      img {
        max-height: 100%;
        max-width: 100%;
        min-height: 0;
        min-width: 0;
      }
    } @else {
      img {
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
      }
    }
}

//Helper

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }

    @if $character == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);;
}
