// Buttons . . .
.btn {
    position: relative; 
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: $fs-base;
    font-family: $ff-base;
    font-weight: $fw--reg;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
} 


.btn--primary {
    @extend .btn;
    @include rem('padding', 10px 25px);
    border: 2px solid $brand-color;
    color: $brand-color;
    line-height: 1;
    font-weight: $fw--bold;

    transform: translateZ(0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-property: color;
    transition-duration: 0.3s;

    &:after {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $brand-color;
        transform: scale(0);
        transition-property: transform;
        transition-duration: 150ms;
        transition-timing-function: ease-out;
    }

    &--white {
        @extend .btn--primary;
        border: 2px solid $snow-white;
        color: $snow-white;
    }
    &--red {
        @extend .btn--primary;
        border: 2px solid $brand-color;
        color: $snow-white;
        background: $brand-color;
    }
    &:hover {
        color: $snow-white!important;
        &:after  {
          transform: scale(1);
      }
  }
}


/*
}
.hvr-rectangle-out
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
  color: white;
}
.hvr-rectangle-out:hover
}
*/