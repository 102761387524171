.article {
    &__title {
        @extend .h1;
        margin-top: .5em;
    }
    &__content {
        @extend .typography;

        img {
            width: auto !important;
            height: auto !important;
        }
    }
    &__media {
        @include keepRatio('16/9');

        figcaption {
            display: block;
            position: relative;
            width: 101%;
            background-color: $white;
        }
    }
    &__intro {
        font-family: $ff-quote;
        margin: 1.5rem 0;
    }
    &__author {
        position: relative;

        .diamond__wrapper {
            position: absolute;
        }
        .article__author__infos {
            @include rem('padding-left', 140px);
            color: $charcoal;
            text-transform: uppercase;
        }
        .article__author__title {
            font-weight: 600;
        }
        .article__author__date {
            //@include rem('margin-top', 5px);
            display: inline-block;
        }
        .sectionTitle {
            display: none;
        }

        @include mq(lg) {
            position: absolute;
            top: 0; left: $gutter-width/2;
            width: 200px;
            text-align: center;

            .article__author__infos {
                @include rem('margin-top', 20px);
                @include rem('padding-left', 0);
                &--offset {
                     @include rem('margin-top', 70px);
                }
            }
            .diamond__wrapper {
                position: relative;
            }
            .sectionTitle {
                display: block;
                margin-top: 0;
            }
        }
    }
}



#diffuseur {
    text-transform: uppercase;
    color: $snow-white;
    height: 285px;
    overflow: hidden;
    background: url(/dist/images/ads-cta-bloc.jpg) center center no-repeat;
    background-size: cover;
    padding: 1em;
    position: relative;
    font-size: 1.25em;
    a {
        position: absolute;
        bottom: 1em;
        left: 1em;
    }
    .article-cta__categorie {
        font-size: .7em;
        color: $snow-white;
        &:before {
            background: $brand-color;
        }
        margin-bottom: 2em;
    }
}


.box-expand {
    form {
        display:none;
        margin: 2em 0;
    }
    >label:before {
        @extend .fa;
        @extend .fa-angle-right;
        color: $darth-vader;
    }
    input#contact-seller {
        height: 1px;
        width: 0;
        opacity: 0;
        &:checked ~ form {
            display:block;
        }
        &:checked ~ label:before {
            @extend .fa-angle-down;
        }
    }

}
