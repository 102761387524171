// ==========================================================================
// Helpers
// ==========================================================================

// Extends
%transition-base {
	transition: all 240ms ease;
}
%transition-base-slow {
	transition: all 480ms ease;
}
// apply as before with relative parent
%list-dot {
	position: absolute;
	top: 6px; left: -13px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5.5px 0 5.5px 6px;
	border-color: transparent transparent transparent $color-secondary;
	border-radius: 2px;
}

// Typography
.white { color: $white; }
.black { color: $black; }
.text-uppercase { text-transform: uppercase; }
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right }
.text-justify { text-align: justify; }
.unlist { list-style-type: none; }
.sr-only { display: none; }

// Layout
.inline { display: inline; }
.block { display: block; }
.inline-block { display: inline-block; }
.table { display: table; }
.table-cell { display: table-cell; }
.flex { display: flex; }
.pull-left { float: left; }
.pull-right { float: right; }

// Spacing
.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }

.p0  { padding: 0 }
