.comments {
	@extend .spaced;
	@extend .spaced--bordered;

	&__write,
	&__listing article {
		@include rem('padding-left', 100px);
		position: relative;
		min-height: 105px;
	}

	&__write {
		.diamond__wrapper {
			//border: 2px solid $xlight-grey;
		}
	}

	&__listing article {
		@include rem('margin-top', 25px);
	}
	&__author {
		text-transform: uppercase;
		font-weight: bold;
		color: $light-grey;
		span {
			font-weight: normal;
			color: $charcoal;
		}
	}
	&__textarea {
		display: block;
		position: relative;
		width: 100%;
		border: 2px solid $xlight-grey;
		background-color: $xxlight-grey;
		
		textarea {
			@include rem('padding', 15px);
			@include rem('font-size', 18px);
			width: 100%;
			min-height: 80px;
			background-color: $xxlight-grey ;
			line-height: 1.1;

			&:focus {
				outline-color: transparent;
			}
			&::-webkit-input-placeholder {
				color: $charcoal;
			}

			&:-moz-placeholder { /* Firefox 18- */
				color: $charcoal;  
			}

			&::-moz-placeholder {  /* Firefox 19+ */
				color: $charcoal;  
			}

			&:-ms-input-placeholder {  
				color: $charcoal;  
			}
		}

		&:after, &:before {
			right: 100%;
			top: 38px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: rgba(136, 183, 213, 0);
			border-right-color: $xxlight-grey ;
			border-width: 12px;
			margin-top: -12px;
		}
		&:before {
			border-color: rgba(194, 225, 245, 0);
			border-right-color: #e4e3e1;
			border-width: 15px;
			margin-top: -15px;
		}
	}

	&__footer {
		p {
			margin:.5em;
		}
		* {
			margin-bottom: 1em;
		}
	}
	.diamond__wrapper {
		position: absolute;
		left: 0;
		top: 0;
        width: calc( 800px / 15 );
        height: calc( 800px / 15 );
        margin: calc( 80px * 25 / 150 );
        .diamond__alphaShape {
            width: 80px;
            height: 80px;
            margin: calc( -80px * 25 / 150 );
        }
    
	}
}
