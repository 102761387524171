.meta-listing {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    @include mq(sm) {
        text-align: left;
    }
    [class~="pagination"] {
        float: none;

        @include mq(sm) {
            float: right;
        }
    }

}
