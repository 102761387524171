.diamond__wrapper {
    width: 80px;
    height: 80px;
    margin: 20px;
    transform: rotate(-45deg);
    overflow: hidden;
    .diamond__alphaShape {
        width: 120px;
        height: 120px;
        margin: -20px;
        transform: rotate(45deg);
        img {
            width: 100%;
            height: 100%;
        }
    }
    &--multiple {
        @extend .diamond__wrapper;
        display: none;
    }
}
@include mq(md) {
    .diamond__wrapper--multiple {
        display: block;
    }
}

@include mq(lg) {
    .diamond__wrapper {
        width: calc( 2000px / 15 );
        height: calc( 2000px / 15 );
        margin: calc( 100px / 3 );
        .diamond__alphaShape {
            width: 200px;
            height: 200px;
            margin: calc( -100px / 3 );
        }
        &--multiple {
            width: calc( 2000px / 30 );
            height: calc( 2000px / 30 );
            margin: calc( 100px / 3 );
            left: calc( 100px / 8 );
            .diamond__alphaShape {
                width: 100px;
                height: 100px;
                margin: calc( -100px / 6 );
            }
            &:nth-child(even){
                position:absolute!important;
                left: calc( 50px + 100px / 8);
                margin-top: -50px;
            }
            &:nth-last-child(2):nth-child(even) {
                margin-bottom: 50px;
            }
        }
    }
}