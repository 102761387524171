
.footer-nav {
    border-top: 1px solid $xlight-grey;
    
    ul.navigation  {
        color: $charcoal;
        li {
            a {
                @include rem('font-size', 14px);
                line-height: 14px;
                font-weight: $fw--semi-bold;
            }
        }
    }
}