// ==========================================================================
// Forms
// ==========================================================================

.form-group {
    position: relative;
}

.label {
    @include rem('font-size', 16px);
    display: inline-block;
    width: 100%;
    font-family: $ff-base;
    text-transform: uppercase;
    color: $charcoal;
    font-weight: $fw--semi-bold;

    &--long {
        @extend .label;
        @include rem('margin-bottom', 5px);
        line-height: 1.6;
    }
}

.input {
    @include rem('padding-left', 13px);
    @include rem('padding-right', 13px);
    @include rem('font-size', 15px);
    @include rem('margin-top', 5px);
    @include rem('margin-bottom', 25px);
    @include placeholder { color: $white; }

    display: inline-block;
    width: $input__width; height: $input__height;
    line-height: $input__height;
    border: $input__border--size solid #cfcfcf;
    font-family: $ff-base;
    font-weight: $fw--reg;
    color: $charcoal;
    outline: 0;

    appearance: none;
    border-radius:0;

    &.parsley-error {
        border-color: #f81943;
    }

    &--search {
        @extend .input;
        border-radius: $input__height/2;
        @include placeholder { color: $xlight-grey; }
        padding-left: 50px;
    }
}

.textarea {
    @include rem('padding', 20px);
    width: 100%;
    height: auto;
    min-height: 150px;
    line-height: 1.7;
    resize: none;
    border: $input__border--size solid #cfcfcf;

    &:focus {
        outline:0;
    }
}

/// Upload files
/// --------------------------------------------------
/// @example html - Usage
///   <div class="upload-file">
///     <input type="file" id="upload" class="js-upload" placeholder="Upload"/>
///     <label for="upload" class="btn btn--secondary">
///			<span class="btn__hover" data-initial="Import file" data-hover="Import file"></span>
///     </label>
///   </div>
/// --------------------------------------------------
.upload-file {
    margin-bottom: 20px;
    // Hide the file input using opacity
    [type=file] {
        position: absolute;
        opacity: 0;
    }

    input,
    [type=file] + label {
        color: $white;
    }

    [type=file] + label {
        position: relative;

        &:after {
            position: relative;
            content: "\f0ab";
            font-family: $ff-fa;
            color: $white;
            float: left;
            margin-right: 8px;
        }

        &.is-active {
            &:after {
                content: "\f058";
                float: right;
                margin-left: 8px;
                margin-right: 0;
            }
        }

        @media screen and (min-width: $nohover-width) {
            &:hover {
                color: $color-secondary;
                &:after {
                    color: $color-secondary;
                }
            }
        }

    }
}

/// Select
/// --------------------------------------------------
/// @example html - Usage
///   <select class="js-select">
///     <option value="hide">All platforms</option>
///     <option value="mobile-game">Mobile game</option>
///		<option value="playstation">Playstation</option>
///     <option value="xbox">Xbox</option>
///   </select>
/// --------------------------------------------------
.select,
.select-options {
    border: $select__border--size solid $white;
}

.select {
    position: relative;
    display: inline-block;
    width: 100%;
    height: $select__height;
    @include rem('font-size', 15px);
    @include rem('margin-top', 5px);
    @include rem('margin-bottom', 25px);
    color: $charcoal;
    cursor: pointer;
    border:0;


    .select-hidden {
        display: none;
        visibility: hidden;
        padding-right: 10px;
    }

    .select-styled {
        position: absolute;
        top: 0; right: 0;
        bottom: 0; left: 0;
        line-height: 34px;
        border: $input__border--size solid #cfcfcf;
        

        @include rem('padding-left', 20px);
        @include rem('padding-right', 20px);
        @extend %transition-base;

        &:after {
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 8.5px 0 8.5px;
            border-color: #000 transparent transparent transparent;
            position: absolute;
            top: 14px;
            right: 18px;
            @include rem('font-size', 22px);
            color: $charcoal;
        }

        &:active, &.is-active {
            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8.5px 7px 8.5px;
                border-color: transparent transparent #000 transparent;
            }
        }
    }

    .select-options {
        width: 100%;
        display: none;
        position: absolute;
        top: 100%;
        right: 0; left: 0;
        background: fade_out(#fff,0);
        border: $input__border--size solid #cfcfcf;
        border-top:0;
        z-index: 50;
        max-height: 500%;
        overflow-y: scroll;

        li {
            list-style-type: none;
            @include rem('padding-top', 4px);
            @include rem('padding-bottom', 4px);
            @include rem('padding-left', 15px);
            @extend %transition-base;

            &:hover {
                color:$charcoal;
                background: #cfcfcf;
            }

            &[rel="hide"] {
                display: none;
            }
        }
    }
    .parsley-errors-list {
        bottom: -20px;

    }
    .parsley-error ~ .select-styled {
        border-color: #f81943;

        &:after {
            border-color: #f81943 transparent transparent transparent;
        }
        &:active, &.is-active {
            &:after {
                border-color: transparent transparent #f81943 transparent;
            }
        }
    }

    &.select--multiple {

        margin-bottom: .7em;

        .select-styled:after {
            width: 38px;
            height: 38px;
            border: 2px solid $brand-color;
            top: -2px;
            right: -2px;
            @extend .fa;
            content:"\f107";
            text-align: center;
            font-size: 35px;
            line-height: 35px;
            color: $brand-color;
        }
        .select-options {
            max-height: 300px;
            overflow: auto;
            > li:first-of-type {
                padding-top: .5em;
                padding-bottom: .5em;
                background-color: $xxlight-grey;
                border-bottom: 2px solid $xlight-grey;
            }
        }

        .tree {
            width: 100%;


            li span {
                text-align: left;
                width: 1em;
            }
        }


        li {
            margin-bottom: 0;



            .checkbox-container {
                display: inline-block;
                margin-top: 3px;
                margin-right: 10px;

                label {
                    line-height: 18px;
                    min-height: 18px;
                    padding-left: 25px;
                    
                    &:before {
                        width: 18px;
                        height: 18px;
                    }
                }
                input[type=checkbox]:checked+label:before {
                    font-size: 14px;
                    line-height: 15px;
                }
            }

            &:hover {
                background-color: transparent;
            }
        }
    }
}

// Extends
// --------------------------------------------------
.typography {
    @include clearfix;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="phone"],
    textarea {
        @extend .input;
    }
    textarea { @extend .textarea; }
    input[type="checkbox"] { @extend .checkbox; }
    input[type="radio"] { @extend .radio; }
}

// Validation
// --------------------------------------------------
.typography {

    .form-sucess {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid rgba($white, 0.5);
        border-bottom: 1px solid rgba($white, 0.5);
        margin-bottom: 20px;
    }

    ul.help-block {
        margin: 0;
    }

    // Errors backend
    // --------------------------------------------------
    .has-error {
        .help-block {
            margin: 0;
            margin-bottom: 20px;
        }
    }
}

// Errors
// --------------------------------------------------
input, textarea, .upload-file {
    border-color: $color-warning;
    margin-bottom: 0;
}

.parsley-errors-list {
    position: absolute;
    bottom:5px;
    list-style: none;
    color:#f81943;
    @include rem('font-size', 12px);
    font-weight: $fw--semi-bold;
    text-transform: none;

}


&.checkbox-group {
    .help-block, .parsley-errors-list {
        margin-bottom: 0;
    }
}

.error, .parsley-required, .parsley-type {
    @include rem('font-size', 14px);
    &:before {
        border-color: transparent transparent transparent $color-warning;
    }
}



// Disable validation icon for certain element
.form-group {
    &.no-validation-icon {
        &.has-error,
        &.parsley-error-group {
            &:after {
                display: none;
            }
        }
    }
}


.radio-container,
.checkbox-container {
    @include clearfix;
    @include rem('margin-top', 5px);


    .parsley-errors-list {
        bottom: -20px;
    }
    .radio.parsley-error label:before,
    .checkbox.parsley-error label:before {
        border: 2px solid #f81943;
    }
    input[type=radio],
    input[type=checkbox] {
        display: none;
    }
    label {
        @include rem('padding-left', 30px);
        position: relative;
        display: inline-block;
        line-height: 24px;
        min-height: 24px;
        cursor: pointer;
        strong {
            font-weight: $fw--semi-bold;
            text-transform: uppercase;
        }
    }
    &--float .radio,
    &--float .checkbox {
        display: inline-block;
    }

    label:before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fff;
        border:2px solid #cfcfcf;
    }
    .checkbox label:before {
        border-radius: 3px;
    }
    .radio label:before {
        border-radius: 50%;
    }
    .radio, .checkbox {
        @include clearfix;
        @include rem('margin-right', 20px);
    }
    .radio label,
    .checkbox label {
        margin-bottom: 10px;
    }

    input[type=radio] + label:after,
    input[type=checkbox] + label:after {
        content:"";
        width: 24px;
        height: 24px;
        position: absolute;
        left:0;
        top:0;
        opacity: 0;
        transition: all 250ms;
        transform: scale(0);
    }
    input[type=radio]:checked + label:after {
        @include rem('left', 0);
        @include rem('top', 0);
        content: "\2022";
        color: $charcoal;
        text-align: center;
        position: absolute;
        left:0;
        top:0;
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        z-index: 1;
        font-weight: normal;
        font-family: $ff-fa;
        font-size: 14px;
        content: "\f111";
        color: $charcoal;
        opacity: 1;
        transform: scale(1);
    }

    input[type=checkbox]:checked + label:before {
        content: "\2713";
        font-size: 16px;
        text-align: center;
        line-height: 21px;
        color: $charcoal;
        font-weight: bold;

    }
}


.form--search {
    &:before {
        @extend .fa;
        @extend .fa-search;
        position: absolute;
        top: 1em;
        left: 1em;
    }
}
