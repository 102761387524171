.main-nav {
    > ul {
        background: $snow-white;
        li {
            border-bottom:1px solid $xlight-grey;
            a {
                overflow: hidden;
                position: relative;
                z-index: 0;
                text-transform: uppercase;
                font-size: 0.813em;
                color: $darth-vader;
                display: block;
                padding: 1.1em;
                transition: all 240ms ease-out;
            }
        }
    }
}

.top-nav {
    background: $snow-white;
    .channels {
        .logo {
            width: 100px;
            padding: 1.5em 0;
            svg {
                path {
                    transition: all 240ms ease-out;
                }
            }
            &--zeste {
                svg {
                    path {
                        fill: $zeste;
                    }
                }
            }
            &--evasion {
                svg {
                    path {
                        fill: $evasion;
                    }
                }
            }
            &:hover * {
                fill: $charcoal;
            }
        }
    }
    .user-button {
        width: 100%;
        padding:0 2em;
        font-weight: bold;
        line-height: calc(#{$nav-height} - 1px);
    }
}

.user-nav .user-close{
    margin-top: 1em;
    font-weight: normal;
}
.user-nav ul li{
    margin: 2em;
}
