.gallery {
    @extend .grid--featured;
    ul { list-style: none; }
    li {
        @extend .col-sm-4;
        picture {
            width: 100%;
            transition: transform 0.5s;
            transform-origin: center center;
        }
        &:hover picture {
            transform: scale3d(1.1,1.1,1);
        }
        figure {
            @include keepRatio('16/9');
        }
    }
}