.pager {
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    text-align: center;
    li {
        display: inline-block;
        span {
            text-transform: uppercase;
            font-weight:600;
        }
        a {
            &:before {
                font-weight:600;
                display: block;
                margin: 2px 1em 0;
            }
            &:hover {
                color: $brand-color;
            }
        }
    }
}

.pagination-container {
    text-align: center;
}

[class~="pagination"] {

    &[class$="container"] {
        text-align: center;
    }

    &[class$="pagination"] {
        display: block;
        overflow: hidden;
        list-style: none;
        font-size: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
        @include mq(sm) {
            margin-top: 0;
            margin-bottom: 0;
            display: inline-block;
        }
    }

    & {
        [disabled] { cursor: default; }

        li {
            display: inline-block;
            //float: left;
            font-weight: bold;

            a,
            span {
                display: inline-block;
                width: 1.3em;
                height: 1.5em;
                line-height: 1.5;

                @include mq(sm) {
                    width: 2.06em;
                    height: 2.06em;
                    line-height: 2.06;
                }

                text-align: center;
                color: #000;
                margin: 0 .5px;

                transition: color 250ms ease-in-out;
            }

            &:not([disabled]) {

                a:hover {
                    color: $brand-color;
                }

                &.current a {
                    color: #fff;
                    background-color: $brand-color;
                }
            }

            &[class^="arrow"] {

                a {
                    margin-right: .5em;

                    &:before {
                        line-height: 1.4;
                        @include mq(sm) {
                            line-height: 2.06;
                        }
                        @extend .fa;
                    }
                }

                &[class*="left"] {

                    a {
                        @extend .fa-angle-left;
                    }

                    &[class$="double"] a {
                        @extend .fa-angle-double-left;
                    }
                }

                &[class*="right"] {

                    a {
                        @extend .fa-angle-right;
                    }

                    &[class$="double"] a {
                        @extend .fa-angle-double-right;
                    }
                }
            }
        }
    }
}
