.repertoireFilter {

    &__title {
        @include rem('font-size', 22px);
        @include rem('margin-bottom', 10px);
        font-weight: bold;
        text-transform: uppercase;
        color: $charcoal;


        &--marginTop {
            @extend .repertoireFilter__title;
            @include rem('margin-top', 10px);
        }

        &--small {
            @extend .repertoireFilter__title;
            @include rem('font-size', 14px);
            vertical-align: baseline;
            display: inline-block;
        }
    }

    &__listing {
        @include rem('font-size', 16px);
        list-style: none;
        margin-left: 0;

        li {
            @include rem('margin-bottom', 5px);
            a {
                transition: color 240ms;
                &:hover {
                    color: color('primary');
                }
            }

        }
        input[type=checkbox] {
        }


    }

    &__selection {
        vertical-align: baseline;
        display: inline-block;
        @include rem('margin-bottom', 16px);

        span {
            @include rem('font-size', 16px);
            @include rem('margin-bottom', 5px);
            font-weight: bold;
            color: $brand-color;
            display: inline-block;
            margin-left:.5em;
        }
        a {
            @include rem('font-size', 12px);
            @include rem('margin', 0 10px 0 5px);
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #e9e9e9;

            &:before,
            &:after {
                content:'';
                position:absolute;
                top:8px;
                background:#6f6f6f;
                width:10px;
                height:2px;
            }
            &:before {
                left:4px;
                transform: rotate3d(0, 0, 1, 45deg);
                transform-origin: 50% 50%;
            }
            &:after {
                left:4px;
                transform: rotate3d(0, 0, 1, -45deg);
                transform-origin: 50% 50%;
            }
        }
    }

}


ul.tree {
    padding: 0;
    width: 300px;

    li  {
        position: relative;
        list-style: none;
        padding-left: 1em;


    }
    li.file {
        margin-left: -1px !important;
    }
    li.file label {
        text-decoration: none;
        display: block;
    }
    &.tree--dropdown {
        li span {
            position: relative;
        }
    }
    li span {
        position: absolute;
        text-align: center;
        left: 0;
        z-index: 2;
        cursor: pointer;
        height: 1em;
        width: 1.5em;
        top: 2px;
    }
    li a + ul,
    li label + ul {
        background: url(toggle-small-expand.png) 40px 0 no-repeat;
        margin: 0; /* 15px */
    }

    li a + ul > li,
    li label + ul > li {
         display: none;
     }

    li label {
        background: url(folder-horizontal.png) 15px 1px no-repeat;
        cursor: pointer;
        display: inline-block;
    }
    li input:checked + ol {
        background: url(toggle-small.png) 40px 5px no-repeat;
        border:1px solid red;
        margin: -1.25em 0 0 -44px; /* 20px */
        padding: 1.563em 0 0 80px;
        height: auto;
    }
    li input:checked + ol > li { display: block; margin: 0 0 0.125em;  }
    li input:checked + ol > li:last-child { margin: 0 0 0.063em; }

    li input:checked + ol.sub-sub-folder {
        padding-left:44px;
    }
    li.file input {
        position: relative;
        opacity: 1;
    }
    li.repertoireFilter__listing--active {
        > a {
            font-weight: 600;
        }
    }
    li.repertoireFilter__listing--daddy {

        & span {
            @extend .fa;
            @extend .fa-caret-right;
        }
        &.is-open > span {
            @extend .fa-caret-down;

            & ~ ul > li {
                display: block;
                margin: 0 0 0.125em;
                &:last-child { margin: 0 0 0.063em; }
            }
        }
    }
}

ul.filter {
    li.repertoireFilter__listing--active {
        > a {
            font-weight: 600;
        }
    }
}
