// ==========================================================================
// Header
// ==========================================================================

body > header {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    label {
        display: none;
    }


    .header__ctas {
        width: 100%;
        background-color: #fff;
        height: $nav-height;
        position: relative;
        z-index: 10;
        border-bottom:1px solid $xlight-grey;
        //box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.25);
    }

    nav {
        position: absolute;
        width: $nav-width;
        display: block;
        transition: left 240ms ease-out, right 240ms ease-out;
        top: 0;
        padding-top: $nav-height;
        height: 100vh;
        background: #fff;
        overflow-y: auto;
    }

    .navicon-button {
        width: 80px;
        height: 100%;
        cursor: pointer;
        z-index: 20;
        transition: all 240ms ease-out;
        display: inline-block;
        position: relative;
        z-index: 11;

        &.is-open {
            .icon-bars {
                top:50%;
                background: transparent;
                transition: all 240ms ease;

                &:before {
                    left:0;
                    top:0;
                    transition: transform 240ms ease-in;
                    transform: rotate3d(0, 0, 1, 45deg);
                    transform-origin: 50% 50%;
                }
                &:after {
                    left:0;
                    top:0;
                    transition: transform 240ms ease-in;
                    transform: rotate3d(0, 0, 1, -45deg);
                    transform-origin: 50% 50%;
                }
            }
        }

        .icon-bars {
            background:$charcoal;
            width:36px;
            height:3px;
            display:block;
            position:absolute;
            left:22px;
            top:38px;
            transition: all 240ms ease-out;

            &:before,
            &:after {
                content:'';
                position:absolute;
                background:$charcoal;
                width:36px;
                height:3px;
                transition: all 240ms ease-out;
            }

            &:before {
                top:-7px;
            }
            &:after {
                top:7px;
            }
        }
    }

    .logo {
        display: inline-block;
        text-align: center;
        padding: 1.5em 0 .625em;
        height: calc(#{$nav-height} - 1px);
        width: auto;
        position: relative;
        z-index: 20;
        line-height: 75px;
          box-sizing: border-box;

        svg {
            width: 100%;
            height: 100%;
            display: inline-block;

            path {
                fill: $brand-color;
            }
        }

        &--espaces {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 120px;
            margin: 0 auto;
            z-index: 0;
        }
    }

    .user-nav {
        right:-$nav-width;
        padding-left: 2em;
        padding-right: 2em;
        label {
            display: block;
        }
        .be-member.btn--primary {
            margin-bottom: 2em;
        }
    }

    .main-nav {
        left:-$nav-width;
    }

    .top-nav {
        position: relative;
        z-index: 12;
        .channels {
            display: none;
        }
        .user-button {
            display: block;
            height: #{$nav-height - 1};
            line-height: #{$nav-height - 1};
            padding-right: calc(2em + 34px);
            background: url(../images/svg/icn-login.svg) calc(100% - 22px) center no-repeat;
            background-size: 34px;
            label {
                display: inline-block;
            }
        }
    }

    .search-button {
        display: inline-block;
        padding: 0 22px;
        line-height: #{$nav-height - 1};
        position: relative;
        z-index: 1;
        background: #fff;
        float: right;
    }

    .search-form {
        position: fixed;
        top:-$nav-height;
        padding: 10px 22px;
        left:0;
        width: 100%;
        background: rgba(0,0,0,.9);
        height: auto;
        transition: top .5s ease-out;
        z-index:9;
        color: $snow-white;
        input {
            color: $snow-white;
        }
        &.is-open {
            top: $nav-height;
        }
    }
}

@include mq(xs) {
    body>header {

    	.top-nav {
    		position: fixed;
    		top: 0;
    		right: 0;
            height: calc(#{$nav-height} - 1px);
            .user-button {
               label {
                    display: none;
                }
            }
    	}
        .search-button {
            float: none;
            border-right: 1px solid $xlight-grey;
        }
        .logo--espaces {
            width: 300px;
        }
    }
}

@include mq(sm) {
    body>header {
        .top-nav .user-button label,
        .search-button label {
            display: inline-block;
        }
        .navicon-button {
            display: none;
        }
        .main-nav {
            position: static;
            width: 100%;
            display: block;
            margin-top: 0;
            height: auto;
            text-align: center;
            li {
                display: inline-block;
                border: 0;
            }
            li a {
                overflow: hidden;
                position: relative;
                z-index: 0;
                text-transform: uppercase;
                font-size: .813em;
                color: $light-grey;
                display: block;
                padding: 0 .675em;
                -webkit-transition: all 240ms ease-out;
                transition: all 240ms ease-out;

                display: inline-block;
                font-weight: 300;

                &.is-active,
                &:hover {
                    color: $brand-color;
                }
            }


            line-height: $subnav-height;
            padding: 0 $gutter-width/2;
            transition: margin-top .5s ease-out;
            z-index: 0;
            box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.25);
            &.is-hidden {
                margin-top: -$subnav-height;
            }

        }
    }
}

@include mq(md) {
    body>header {
        .top-nav .user-button,
        .top-nav .channels {
            display: inline-block;
            float: right;
            width: auto;
        }
        .main-nav {
            li a {
                padding: 0 1.1em;
            }
        }
    }
}


.zoom {
    perspective: 2000px;
    padding-top: calc(#{$nav-height});
    & > * {
        position: relative;
        left: 0;
        transition: transform 0.5s;
    }
    .has-mobilenav-open & > *,
    .has-usernav-open & > * {
        transform: translateZ(-50px);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    @include mq(sm) {
        padding-top: calc(#{$nav-height} + #{$subnav-height});
        .has-mobilenav-open & > * {
            transform: none;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.2);
    content: '';
    opacity: 0;
    transition: opacity 0.5s;
    z-index:-1;
    -webkit-backdrop-filter: blur(5px);
}

.has-mobilenav-open,
.has-usernav-open {
	overflow: hidden;
    .overlay {
        opacity: 1;
        z-index:10;
    }
}
.has-mobilenav-open {
    header .main-nav {
        left: 0;
        box-shadow: 5px 0px 5px -1px rgba(0,0,0,0.25);
    }
}
.has-usernav-open {
    header .user-nav {
        right: 0;
        box-shadow: -5px 0px 5px -1px rgba(0,0,0,0.25);
    }
}

@include mq(sm) {
    .has-mobilenav-open {
        overflow: auto;
        .overlay {
            opacity: 0;
            z-index:-1;
        }
        header .main-nav {
            box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.25);
        }
    }
}
