.tabNav {
    list-style: none;
    overflow: hidden;
    >li {
        width: 50%;
        float: left;
        text-align: center;
    }
}



.tabs {
    @extend .btn--primary;
    &.is-active {
        color: $snow-white;
        &:after  {
          transform: scale(1);
      }
    }
}

.tabPanels .toggle {
    display:none;
    padding-top: 2em;
}



.accordion-tabs {
    
    @include clearfix;
    margin: 0;
    padding: 0;
    text-align: center;

    .tab {
        display: inline;
        
    }
    .tab-link {
        @extend .btn--primary--red;
        display: inline-block;
        width:49%;
    }
    .tab-content {
        display: none;
        float: left;
        text-align: left;
        border-bottom: 1px solid #e4e3e1;
        width: 100%;
        padding: 1rem 10px;
        hr {
            display: none;
        }
        &.is-open {
            display: block;
        }
    }

    @media only screen and (min-width: 768px){
        .tab-link {
            display: none;
        }
        .tab-content {
            display: block;
            float: none;
            padding: 0;
            border: 0;
            hr {
                display: block;
            }
        }
    }
    
}