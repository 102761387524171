// ==========================================================================
// Header
// ==========================================================================

$secondary-color: #ab060a;
$dark-grey: grey;
$grey: grey;
$light-grey: grey;
$font-bold: 'Arial';
$nav-width: 320px;
$nav-height: 75px;
$border-color: #e4e3e1;


body > header {


    .navicon-button {
        background-color: $snow-white;
        border-right:1px solid $border-color;

        .icon-bars {

            &:before,
            &:after {
            }
        }
    } 

    .logo {


        &--espaces {

            img,svg{
                height: 100%;
            }
        }
    }

    .user-nav {
    }

    .top-nav {

        .channels { 
            .logo {
                svg {
                    path {
                    }    
                }
                &--zeste {
                    position: relative;
                    top: 3px;
                    svg {
                        width: 41px;
                        height: 24px;
                        position: relative;
                        left:0;
                        path {
                        }    
                    }
                }
                &--evasion {
                    svg {
                       width: 65px;
                       height: 24px;
                       position: relative;
                       left:0;
                       path {
                       }    
                   }
               }
               &:hover * {
               }
           }
       }

       .user-button {
        border-left: 1px solid $border-color;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        i:before {
            /*@include rem('font-size', 23px); */
            @include rem('padding-left', 5px); 
            line-height: 74px;
        }

    }
}

.search-button {

    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    i:before {
        @include rem('font-size', 23px); 
        @include rem('padding-right', 5px); 
        line-height: 74px;
    }
}
.search-form {
}


.logo-globalia {
    span {
    }
}
.logo-globalia {  
}

.shortcuts {
    ul {
        li {
        }
    }
}    
}