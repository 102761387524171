// ==========================================================================
// Footer
// ==========================================================================

footer {
	border-top: 1px solid $xlight-grey;
	overflow: hidden;

    .footer-nav {
        position: relative;
        padding: .75em $gutter-width/2;
    }

    .legal,
    .navigation {
        display: block;
        text-align: center;
        @include mq(xs){
            display: inline-block;
            text-align: left;
            padding: .75em 0;
            width: calc(100% - 180px);
            margin-left: 180px;
        }
    }

	.navigation {
		display: none;
        list-style: none;
        li {
        	display: inline-block;
        	a {
        		text-transform: uppercase;
        		color: $brand-color;
        	}
            &:not(:last-child):after {
                content: "|";
                padding: 0 .5em;
                line-height: 1;
            }
        }
        @include mq(xs){
            display: inline-block;
            padding-bottom: 0;
        }
	}

	.logo--espaces {
        display: block;
        text-align: left;
        margin: 0 auto;
        padding: 0 0 .625em;
        height: calc(#{$nav-height} - 1px);
        z-index: 20;
        width: 180px;
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: $charcoal;
            }
        }
        @include mq(xs){
            position: absolute;
            top: .75em;
            left: $gutter-width/2;
             padding-right: $gutter-width;
        }
    }
}